import React, {useState} from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Lightbox from "react-image-lightbox"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import "react-image-lightbox/style.css"

export const query = graphql`
  query($slug: String) {
    projectsJson(slug: { eq: $slug }) {
      id
      title
      description
      slug
      gridColumn
      gridRow
      logo {
        childImageSharp {
          fluid(maxHeight: 50, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image {
        childImageSharp {
          fluid(maxWidth: 640, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      images {
        image {
          childImageSharp {
            fluid(maxWidth: 640, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gridColumn,
        gridRow
      }
      imagesFull: images {
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gridColumn,
        gridRow
      }
    }
  }
`

const ProjectPage = props => {
  const [photoIndex, setPhotoIndex ] = useState(0)
  const [isOpen, setIsOpen ] = useState(false)
  const project = props.data.projectsJson
  return (
    <Layout className="subpage">
      <SEO
        title={project.title}
        description={project.description}
        image={
          project.image ? project.image.childImageSharp.fluid.src : undefined
        }
      />

      <Container>
        <section>
          <Container className="project-details">
            <Row>
              <Col>
                <h2>
                  {project.title}
                  {project.logo && <img src={project.logo.childImageSharp.fluid.src} className="logo" alt="Logo" title="Logo" />}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col className="masonry">
              <div style={{gridColumn: project.gridColumn, gridRow: project.gridRow}} className="content">
                {project.description.map(el => (
                  <p dangerouslySetInnerHTML={{ __html: el }} />
                ))}
              </div>
              {project.imagesFull.map((item, key) => {
                return (
                  <div onClick={() => {setIsOpen(true); setPhotoIndex(key)}} className="pointer" style={{gridColumn: item.gridColumn, gridRow: item.gridRow}}>
                    <Img fluid={item.image.childImageSharp.fluid} key={key} />
                  </div>
                )
              })}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
      {isOpen && (
          <Lightbox
            mainSrc={project.imagesFull[photoIndex].image.childImageSharp.fluid.src}
            nextSrc={
              project.imagesFull[(photoIndex + 1) % project.imagesFull.length].image.childImageSharp
                .fluid.src
            }
            prevSrc={
              project.imagesFull[(photoIndex + project.imagesFull.length - 1) % project.imagesFull.length].image
                .childImageSharp.fluid.src
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + project.imagesFull.length - 1) % project.imagesFull.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % project.imagesFull.length)
            }
          />
        )}
    </Layout>
  )
}

export default ProjectPage
